import {combineReducers} from "@reduxjs/toolkit"

import dialogReducer from "@slices/dialog.slice"
import emergencyReducer from "@slices/emergency.slice"
import departmentReducer from "@slices/department.slice"
import noticeBoardSlice from "../slices/notice-board.slice";
import loaderSlice from "@slices/loader.slice";

const rootReducer = combineReducers({
    dialogs: dialogReducer,
    emergency: emergencyReducer,
    departments: departmentReducer,
    noticeBoards: noticeBoardSlice,
    loader: loaderSlice
})

export default rootReducer
