import { startLoading, stopLoading } from "@slices/loader.slice"
import { store } from "@store"
import axios from "axios"
import { toast } from "react-toastify"


const api = axios.create({
    baseURL: "https://apinoticeboard.bdoee.industrialdata.in/"
})

api.interceptors.request.use(
  (config) => {
    store.dispatch(startLoading())
    return config
  },
  (error) => {
    store.dispatch(stopLoading())
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    store.dispatch(stopLoading())

    if (response.status === 201) {
      toast.success(response.data)
    }

    return response
  },
  (error) => {
    store.dispatch(stopLoading())
    return Promise.reject(error)
  }
)

export default api