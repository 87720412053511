import { ApplicationResponse, TaggedDataState } from "@common/domain/common.model"
import { TaggedAction } from "@common/domain/common.props"
import api from "@common/services/api.service"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  EmergencyScreenRef,
  EmergencyStore,
  NewEmergencyScreenRef
} from "@src/views/emergency-screen/domain/emergency.model"

export const fetchEmergencyScreen =
  createAsyncThunk<TaggedDataState<EmergencyScreenRef[]>, TaggedAction>("emergencyScreen/fetchEmergencyScreen",
    async (payload) => {
      const { config } = payload.parameters

      const response = await api.get<ApplicationResponse<EmergencyScreenRef[]>>(`https://seal-app-ymus4.ondigitalocean.app/emergency/list?config=${config}`)

      return {
        tag: payload.tag,
        data: response.data.data || []
      }
    })

export const uploadEmergencyScreen = createAsyncThunk<any, {
  config?: string,
  payload: NewEmergencyScreenRef
}>("emergencyScreen/upload",
  async (wrapper, thunkAPI) => {
    try {
      const url = "https://seal-app-ymus4.ondigitalocean.app/emergency/manage"
      const {
        config,
        payload
      } = wrapper
      const keys = Object.keys(payload) as Array<keyof NewEmergencyScreenRef>

      const response = await api.post<ApplicationResponse<void>>(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      return response.data
    } catch (err: any) {
      if (!err.response) {
        return thunkAPI.rejectWithValue(err.response.data)
      }

      return thunkAPI.rejectWithValue({
        error: {
          message: "Network error",
          code: "NETWORK_ERROR",
          attributes: {}
        },
        status: "error",
        timestamp: new Date().toISOString()
      })
    }
  })

const emergencySlice = createSlice({
  name: "emergency",
  initialState: {} as EmergencyStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmergencyScreen.pending, (state, action: { meta: { arg: TaggedAction } }) => {
        const { arg: { tag } } = action.meta

        state[tag] = {
          status: "loading",
          error: null,
          data: []
        }
      })
      .addCase(fetchEmergencyScreen.fulfilled, (state, action) => {
        const {
          tag,
          data
        } = action.payload
        state[tag] = {
          status: "idle",
          error: null,
          data
        }

      })
      .addCase(fetchEmergencyScreen.rejected, (state, action) => {
        const { arg: { tag } } = action.meta

        state[tag] = {
          status: "failed",
          error: action.payload,
          data: []
        }
      })
  }
})
export default emergencySlice.reducer