import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApplicationResponse} from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import {NoticeBoardDataRef, NoticeBoardStore, NoticeBoardTabsRef} from "@views/home/domain/home.model";
import convertToForm from "@src/utils/payload-to-form-converter";
import {showErrorToast, showPendingToast, showSuccessToast} from "@src/utils/toast.utils";

export const uploadNoticeBoard = createAsyncThunk<any[], any>(
    "configData/uploadNotice", async (payload) => {
        const keys = Object.keys(payload)
        const formData = convertToForm(payload, keys)
        const response = await api.put<ApplicationResponse<any[]>>("/board/config", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        return response.data.data as any[]
    }
)

export const fetchNoticeBoardData = createAsyncThunk<NoticeBoardDataRef, string>(
    "tabs/fetchNoticeBoardData", async (deviceId, {rejectWithValue}) => {
        try {
            const response = await api.get<ApplicationResponse<NoticeBoardDataRef>>(`/board/config?deviceId=${deviceId}`)
            return response.data as NoticeBoardDataRef
        } catch (err: any) {
            if (!err.response) {
                return rejectWithValue(err.response.data);
            }
            return rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code,
                },
            });
        }
    }
)

export const fetchNoticeBoardTabs = createAsyncThunk<NoticeBoardTabsRef[], void>(
    "tabs/fetchNoticeBoardTabs", async (_, {rejectWithValue}) => {
        try {
            const response = await api.get<ApplicationResponse<NoticeBoardTabsRef[]>>("/board/minimal")
            console.log("response.......", response)
            return response.data as NoticeBoardTabsRef[]
            // return [] as NoticeBoardTabsRef[]
        } catch (err: any) {
            if (!err.response) {
                return rejectWithValue(err.response.data);
            }
            return rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code,
                },
            });
        }
    }
)

const noticeBoardSlice = createSlice({
    name: "noticeBoard",
    initialState: {
        noticeBoardDataMinimal: {
            data: {} as NoticeBoardDataRef,
            status: "loading",
            error: null
        },
        noticeBoardTabsMinimal: {
            data: [],
            status: "loading",
            error: null
        },

    } as NoticeBoardStore,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchNoticeBoardTabs.pending, (state) => {
            state.noticeBoardTabsMinimal = {
                data: [],
                error: null,
                status: "loading"
            }
        }).addCase(fetchNoticeBoardTabs.fulfilled, (state, action) => {
            state.noticeBoardTabsMinimal = {
                data: action.payload,
                error: null,
                status: "idle"
            }
        }).addCase(fetchNoticeBoardTabs.rejected, (state, action) => {
            state.noticeBoardTabsMinimal = {
                data: [],
                error: action.error.message,
                status: "failed"
            }
        })
            .addCase(fetchNoticeBoardData.pending, (state) => {
                state.noticeBoardDataMinimal = {
                    data: {} as NoticeBoardDataRef,
                    error: null,
                    status: "loading"
                }
            }).addCase(fetchNoticeBoardData.fulfilled, (state, action) => {
            state.noticeBoardDataMinimal = {
                data: action.payload,
                error: null,
                status: "idle"
            }
        }).addCase(fetchNoticeBoardData.rejected, (state, action) => {
            state.noticeBoardDataMinimal = {
                data: {} as NoticeBoardDataRef,
                error: action.error.message,
                status: "failed"
            }
        }).addCase(uploadNoticeBoard.pending, (state, action) => {
            showPendingToast("Uploading data.")
        })
            .addCase(uploadNoticeBoard.fulfilled, (state, action) => {
                showSuccessToast("Successfully uploaded data.")
                window.location.reload()
            }).addCase(uploadNoticeBoard.rejected, (state, action) => {
            showErrorToast("Failed to upload data.")
        })
    }
})

export default noticeBoardSlice["reducer"];