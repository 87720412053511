import DialogOverlay from "@components/dialog"


import Loader from "@layouts/components/loader"

import { store } from "@store"
import React, { lazy, Suspense } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import "./index.scss"

import reportWebVitals from "./reportWebVitals"


const container = document.getElementById("root")!
const root = createRoot(container)

const LazyApp = lazy(() => import("./App"))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
          <DialogOverlay />
          <LazyApp />
        </Suspense>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
