import {toast} from "react-toastify";
import React from "react";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {HiMiniXCircle} from "react-icons/hi2";
import { MdOutlinePending } from "react-icons/md";

export function showSuccessToast(message?: string) {
    toast(
        <div className="flex items-start">
            <div className="flex-shrink-0">
                <AiOutlineCheckCircle className="h-6 w-6 text-green-400" aria-hidden="true"/>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">Success!</p>
                <p className="mt-1 text-sm text-gray-500">{message ?? "Request completed successfully!"}</p>
            </div>
        </div>, {
            className: "pointer-events-auto w-full max-w-sm !overflow-hidden !rounded-lg !bg-white !shadow-lg !ring-1 !ring-black !ring-opacity-5",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
        })
}

export function showErrorToast(message?: string) {
    toast(
        <div className="flex items-start">
            <div className="flex-shrink-0">
                <HiMiniXCircle className="h-6 w-6 text-red-400" aria-hidden="true"/>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">Error!</p>
                <p className="mt-1 text-sm text-gray-500">{message ?? "Encountered Issues while processing your request!"}</p>
            </div>
        </div>, {
            className: "pointer-events-auto w-full max-w-sm !overflow-hidden !rounded-lg !bg-white !shadow-lg !ring-1 !ring-black !ring-opacity-5",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
        })
}

export function showPendingToast(message?: string) {
    toast(
        <div className="flex items-start">
            <div className="flex-shrink-0">
                <MdOutlinePending className="h-6 w-6 text-blue-400" aria-hidden="true"/>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">Uploading!</p>
                <p className="mt-1 text-sm text-gray-500">{message ?? "Request completed successfully!"}</p>
            </div>
        </div>, {
            className: "pointer-events-auto w-full max-w-sm !overflow-hidden !rounded-lg !bg-white !shadow-lg !ring-1 !ring-black !ring-opacity-5",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
        })
}
