import { ApplicationResponse } from "@common/domain/common.model"
import { TaggedAction } from "@common/domain/common.props"
import api from "@common/services/api.service"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const uploadModel = createAsyncThunk<any, {
  lineId?: string,
  payload: any
}>("department/upload",
  async (wrapper, thunkAPI) => {
    try {
      const url = "https://seal-app-ymus4.ondigitalocean.app/departments/new"
      const {
        payload
      } = wrapper

  
      const response = await api.post<ApplicationResponse<void>>(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      return response.data
    } catch (err: any) {
      if (!err.response) {
        return thunkAPI.rejectWithValue(err.response.data)
      }

      return thunkAPI.rejectWithValue({
        error: {
          message: "Network error",
          code: "NETWORK_ERROR",
          attributes: {}
        },
        status: "error",
        timestamp: new Date().toISOString()
      })
    }
  })


export const fetchDepartmentsList = createAsyncThunk<any[], TaggedAction>("departments/fetchDepartments",
  async () => {
    // const { config } = payload.parmeters
    const response = await api.get<ApplicationResponse<any[]>>(`https://seal-app-ymus4.ondigitalocean.app/departments/list`)

    return response.data.data || []
  })


const departmentSlice = createSlice({
  name: "departments",
  initialState: { minimal: { status: "idle", error: null, data: [] } } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartmentsList.pending, (state) => {
        state.minimal = {
          status: "loading",
          error: null,
          data: []
        }
      })
      .addCase(fetchDepartmentsList.fulfilled, (state, action) => {
        const data = action.payload
        state.minimal = {
          status: "idle",
          error: null,
          data
        }
      })
      .addCase(fetchDepartmentsList.rejected,
        (state, action) => {
          state.minimal = {
            status: "failed",
            error: action.error.message,
            data: []
          }
        })
  }
})

export default departmentSlice.reducer